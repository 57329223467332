@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#E5E5E5";
  box-sizing: border-box;
  /* -webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto; */
  /* font-size: 14px; */
}
h1,
h2,
h3 {
  line-height: 1;
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-image-preview-img {
  /* margin: -24% auto; */
  display: flex;
  align-items: center;
}
ul {
  list-style: disc !important;
}
ol {
  list-style: auto !important;
}
h1 {
  font-size: 2em !important;
}
#SLRMDEditor .CodeMirror,
.CodeMirror-scroll,
.EasyMDEContainer .CodeMirror-scroll {
  /* min-height: 60vh !important; */
  /* max-height: 60vh !important; */
  padding-bottom: 10vh !important;
  /* overflow-y: auto !important; */
}
#SLRMDEditor .CodeMirror-code {
  padding-bottom: 60vh !important;
  white-space: pre-wrap !important;
}
#SLRMDEditor .CodeMirror-lines {
  padding-bottom: 60vh !important;
  white-space: pre-wrap !important;
}
#SLRMDEditor p {
  margin-bottom: 0.8rem !important;
}
#SLRMDEditor a {
  color: rgb(37, 64, 240) !important;
}
#SLRMDEditor h2 {
  font-size: 1.5em !important;
  margin-bottom: 0.85rem !important;
}
#introEditor h1 {
  font-size: 1.5em !important;
  margin-bottom: 1rem !important;
}
#SLRMDEditor h3 {
  font-size: 1.2em !important;
  margin-bottom: 0.7rem !important;
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
