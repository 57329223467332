.Subtabs {
  background: rgba(128, 128, 128, 0.08);
  height: 50px;
}
.subMenu a {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: #454250;
}
.subMenu a:focus {
  color: rgb(39, 166, 164) !important;
}
.subMenu .active {
  color: rgb(39, 166, 164) !important;
  padding-bottom: 0.6em;
  border-bottom: 4px solid rgb(39, 166, 164) !important;
}

.subMenu a:hover {
  color: rgb(39, 166, 164) !important;
}
/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
	color: rgb(39, 166, 164) !important;
	border-bottom: 4px solid rgb(39, 166, 164) !important;
} */

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  /* border-bottom: 0px solid white !important; */
  color: #27a6a4 !important;
  border-color: #27a6a4 !important;
}

.ant-menu-title-content:hover,
.ant-menu-title-content:hover::after {
  color: #27a6a4 !important;
}

.ant-menu-item-selected,
.ant-menu-item-active {
  color: #27a6a4 !important;
}

.Modal {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.section1 {
  background-color: rgb(218, 116, 116);
  /* opacity: 0; */
}

.section2 {
  background-color: rgb(137, 137, 230);
  /* opacity:0; */
}

.section3 {
  background-color: rgb(125, 211, 156);
}
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
  background-color: red;
}

.step.finish {
  background-color: #04aa6d;
}

.tab {
  display: none;
}
