.CodeMirror,
.CodeMirror-scroll {
  min-height: 0 !important;
  max-height: 110px !important;
}
.CodeMirror-lines {
  height: 150px;

  /* overflow-y: scroll;
	display: flex;
	flex-direction: column-reverse; */
}

.ant-btn-primary {
  background-color: #27a6a4;
  border-color: #27a6a4;
}
.ant-btn-primary:hover {
  background-color: #27a6a4;
  border-color: #27a6a4;
}

.ant-btn-primary::selection {
  background-color: #27a6a4;
  border-color: #27a6a4;
}
.ant-input:focus {
  border-color: #000000;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-input:hover {
  border-color: #4e4e4e;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid rgb(0, 0, 0) !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2) !important;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) !important;
}

.ant-btn.ant-btn-dashed.ant-btn-block:focus {
  border-color: #000000;
  color: #000000;
}
.ant-btn.ant-btn-dashed.ant-btn-block:hover {
  border-color: #27a6a4;
  color: #27a6a4;
}

.ant-switch {
  background-color: rgb(154, 148, 148);
}

.ant-switch-inner {
  color: rgb(255, 255, 255);
}

.ant-switch-handle {
  color: #27a6a4;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #27a6a4 !important;
}

.ant-tabs-tab:hover {
  color: #27a6a4 !important;
  border-bottom-color: #27a6a4 !important;
}

.ant-tabs-ink-bar {
  border-bottom-color: #27a6a4 !important;
  background: #27a6a4;
}
.tableFormField {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}
.tableHeaderInput:focus {
  cursor: none !important;
  border: none !important;
}
.form5 .ant-form-item-control {
  flex-direction: row !important;
}
.form6 .ant-form-item-control {
  flex-direction: row !important;
}
.chartFormField {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}
.chartHeaderInput:focus {
  cursor: none !important;
  border: none !important;
}
.addBtn {
  background: transparent;
}
.addBtn:hover {
  color: #27a6a4;
}
.formInput,
.formSelect {
  margin: 0;
}
.formItem {
  margin-bottom: 16px;
  height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .formItem::-webkit-scrollbar {
	display: none;
} */
/* .formItemOverview {
	margin-bottom: 16px;
	height: 200px;
	overflow-y: scroll;
	overflow-x: hidden;
} */
