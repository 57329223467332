#DBButtons .ant-radio-button-wrapper-checked {
  background-color: #27a6a4 !important;
  border-color: #27a6a4 !important;
  color: whitesmoke !important;
}
#DBButtons .ant-radio-button-wrapper:hover {
  background-color: #27a6a4 !important;
  border-color: #27a6a4 !important;
  color: whitesmoke !important;
}
.searchDBTable thead[class*="ant-table-thead"] th {
  background-color: #27a6a4 !important;
  border: none !important;
  font-weight: 600 !important;
  color: whitesmoke !important;
}
.searchDBTable thead[class*="ant-table-thead"] tr:hover {
  background-color: #27a6a4 !important;
  border: none !important;
}
.searchDBTable .ant-table-tbody td {
  padding: 10px 16px !important;
}

.searchDBTable td.ant-table-column-sort {
  background-color: transparent !important;
}
.table-row-white {
  background-color: white !important;
}
.table-row-color {
  background-color: #eaf8f8 !important;
}
#EditFormSearch .ant-form-item {
  margin-bottom: 4px !important;
}

#EditFormSearch .ant-input-number-input {
  height: 27px !important;
}
