.outcomesTable th {
  border: none !important;
}
.outcomesTable td {
  height: 47.05px !important;
}
.heatmapTable td {
  padding: 0 !important;
  border: 0 !important;
}
.outcomesForm .ant-form-item {
  margin-bottom: 0 !important;
}
.outcomesForm .ant-input-number-sm {
  width: 4rem !important;
}
.outcomesTable tr :hover {
  background-color: #eaf8f8 !important;
}

.qualityTable th {
  border: none !important;
}
.qualityTable td {
  height: 47.05px !important;
}

.qualityTable thead th {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.forestPlotTable tbody td {
  padding: 0 !important;
}
.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}
.react-flow__node-textUpdater {
  min-height: 35px;
  min-width: 15rem;
  border: 1px solid #eee;
  /* padding: 5px; */
  border-radius: 5px;
  /* background: white; */
}
.react-flow__attribution {
  display: none !important;
}
.ant-popover-inner-content {
  padding: 0.5rem !important;
}

/* p {
  color: red !important;
} */

textarea {
  white-space: pre-wrap !important;
}
