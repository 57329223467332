.carousel .thumb {
	width: 223px !important;
	height: 100px;
	position: relative;
	opacity: 20%;
}

.carousel .thumb.selected {
	opacity: 100%;
	width: 223px;
}

.carousel-component {
	position: relative;
	height: calc(100vh - 16%);
	overflow-y: scroll;
}

.carousel-component::-webkit-scrollbar {
	display: none;
}

.slider-wrapper {
	height: 100% !important;
	background-color: rgb(244, 243, 243);
	padding-top: 30px;
}

.thumbs.animated {
	width: 255px;
	/* margin-left: 15px; */
	margin: 0 !important;
}

.carousel {
	position: absolute;
	/* left: -63px; */
	top: 0px;
	z-index: 0;
	background-color: rgb(219, 219, 219);
}

.main-slide {
	background-color: white !important;
	margin-top: 40px !important;
	/* left: -208px; */
	/* top: 39px; */
	display: grid;
	grid-template:
		"image image image image image image header"
		"body body body body body body body";
}

.slide {
	background-color: white !important;
	margin-top: 40px !important;
}

.carousel-slider {
	position: relative;
	right: 0;
	left: 0;
	top: 102px;
	height: 90%;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
	border: 0px;
	width: 223px !important;
	padding: 0px !important;
}

.slide {
	height: 400px;
}

.control-dots {
	top: 380px;
}

.carousel.carousel-slider {
	position: relative;
	margin: 0;
	overflow: hidden;
	background: white !important;
}

.carousel-root {
	height: 1000px;
}

.control-prev {
	height: 45% !important;
	margin-top: 24px !important;
}

.control-next {
	height: 45% !important;
	margin-top: 24px !important;
}

.message {
	/* width: 100%; */
	/* float: right; */
	background: #d8ecfb;
	/* height: 406px; */
	margin-top: -41px;
}

.text {
	margin-top: 144px;
	padding-left: 40px;
	padding-right: 40px;
}

/* .thumbs {
	margin-top: -38px;
} */

.axis-horizontal {
	margin-top: -45px !important;
}

.axis-horizontal {
	margin-top: -45px !important;
}

.indicator {
	cursor: pointer;
	padding: 10px;
	text-align: center;
	border: 1px #666 solid;
}

.indicator.active {
	color: #fff;
	background: #666;
}

/* .zoom-wrapper{
  display: block !important;
} */

.outerdiv {
	position: relative;
}

.indicators {
	position: absolute;
	top: 0;
	left: 0;
}

.slidediv {
	position: relative;
}

.react-slideshow-wrapper.slide {
	margin-top: 10px !important;
	left: 0;
	height: auto;
}
.messageIcons:hover {
	color: #ec7200;
	transform: scale(110%);
}
