.settings {
	display: flex;
	align-items: center;
	justify-content: center;
}
.settings .card-container {
	/* height: 40vh; */
	margin: 0em 0em;
	width: 100%;
	background-color: white;
}
.settings .ant-tabs-nav {
	margin-bottom: 0 !important;
}
.settings .ant-tabs-content {
	border-top: 1px solid #f3f4f4;
}
.settings .card-container p {
	margin: 0;
}
.settings .card-container > .ant-tabs-card .ant-tabs-content {
	margin-top: -16px;
}
.settings
	.card-container
	> .ant-tabs-card
	.ant-tabs-content
	> .ant-tabs-tabpane {
	padding: 16px;
	background: #fff;
}
/* .settings .card-container > .ant-tabs-card > .ant-tabs-nav::before {
	display: none;
	color: red !important;
} */
.settings .card-container .ant-tabs-tab .ant-tabs-tab-btn {
	color: #454250;
	font-weight: 500;
}
.settings .card-container .ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #27a4a6;
	font-weight: 500;
}
.settings .card-container .ant-tabs-tab:hover {
	color: #27a4a6;
}

.settings .not-authorised {
	height: 24rem;
	padding: 1rem;
	width: 100%;
}
.settings .not-authorised .content {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.settings .not-authorised .text {
	font-size: 1.25rem /* 20px */;
	line-height: 1.75rem /* 28px */;
	margin: 0;
}
