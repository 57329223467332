.executiveCollapse .ant-collapse {
  border: none !important;
}
.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}
.react-flow__node-textUpdater {
  min-height: 35px;
  min-width: 15rem;
  border: 1px solid #eee;
  /* padding: 5px; */
  border-radius: 5px;
  /* background: white; */
}
.react-flow__attribution {
  display: none !important;
}
.ant-popover-inner-content {
  padding: 0.5rem !important;
}

.editTableModal .ant-modal-header {
  background-color: #27a6a4 !important;
}
.outcomeModal .ant-modal-body {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
#introEditor .CodeMirror,
.CodeMirror-scroll,
.EasyMDEContainer .CodeMirror-scroll {
  min-height: 60vh !important;
  max-height: 60vh !important;
  padding-bottom: 10vh !important;
}

#introEditor .CodeMirror-code {
  padding-bottom: 15vh !important;
  white-space: pre-wrap !important;
}
#introEditor p {
  margin-bottom: 0.8rem !important;
}
#introEditor a {
  color: rgb(37, 64, 240) !important;
}
#introEditor h2 {
  font-size: 1.5em !important;
  margin-bottom: 0.85rem !important;
}
#introEditor h1 {
  font-size: 1.5em !important;
  margin-bottom: 1rem !important;
}
#introEditor h3 {
  font-size: 1.2em !important;
  margin-bottom: 0.7rem !important;
}
.introImage {
  background: url("../pictures/introductionPicture.png");
  /* background-image: linear-gradient(to top, #454250, #10182073),
    url(https://assets.gettyimages.com/bf-boulder-whitelabelbucket-getty-prod/98hjm4j449qv93jpvq7qb9g7/v/53462656/original/1219375103.jpg); */
  background-color: "#E5E5E5";
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center !important;
  background-size: cover !important;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  font-family: "Open Sans", sans-serif;
  /* letter-spacing: 0.2em; */
}

.introCarousel .ant-carousel .slick-dots li button {
  background: black !important;
  opacity: 1;
  bottom: 0 !important;
}

.introCarousel .ant-carousel .slick-dots li.slick-active button {
  background: black !important;
  opacity: 1;
  height: 5px !important;
}

textarea {
  white-space: pre-wrap !important;
}
