.evidenceTabs .ant-tabs-nav-wrap {
  margin-top: 0 !important;
}
.fixedHeader thead th {
  position: sticky;
  top: 0;
}
.editTableModal .ant-modal-header {
  background-color: #27a6a4 !important;
}
.zoomableMap {
  width: 100%;
  height: 485px;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  position: absolute;
}
/* #tooltip {
  opacity: 0;
  position: absolute;
}
#mapBox:hover #tooltip {
  opacity: 1;
} */
