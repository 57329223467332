table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

/* table td, */
table th {
  border-left: 1px solid #ddd;
  padding: 1em 0.2em;
}
table th:last-child {
  border-right: 1px solid #ddd;
}
table td {
  padding: 0.8em 0.2em;
  border-bottom: 1px solid #ddd;
}
/* table tr:nth-child(even) {
	background-color: #f2f2f2;
} */

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f4f3f3;
  color: #454250;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableSearch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.tableSearch button {
  border: none;
  background: transparent;
  color: #27a6a4;
  cursor: pointer;
}
