.anticon {
  vertical-align: 0.125rem !important;
}

.ant-checkbox-group-item {
  display: flex;
  margin-right: 0;
  margin-bottom: 0.2rem !important;
}

.HeatmapStudyTable thead[class*="ant-table-thead"] th {
  background-color: transparent !important;
  border: none !important;
}

a:hover {
  color: rgb(39, 166, 164) !important;
}

input {
  outline: none;
}

textarea {
  outline: none;
}

.ant-btn-default:hover {
  border-color: #27a6a4 !important;
  color: #27a6a4 !important;
}

.ant-btn-default {
  border-color: #27a6a4 !important;
  color: #27a6a4 !important;
  border-radius: 0.2rem !important;
}

::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.6rem;
}
::-webkit-scrollbar-track {
  background: #ececec;
  /* border: 1px solid #adadad;
  border-radius: 5px; */
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-breadcrumb-link a {
  color: whitesmoke !important;
}
.ant-breadcrumb-link a:hover {
  color: #ec7200 !important;
}
.ant-breadcrumb-separator {
  color: whitesmoke !important;
}
.ant-breadcrumb {
  font-size: 0.72rem !important;
  padding-bottom: 0 !important;
}
.CodeMirror-sizer {
  margin-bottom: 40px !important;
}

.landingBackground {
  background: url("../pictures/HomeBackground.jpg");
  background-color: "#E5E5E5";
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center !important;
  background-size: cover !important;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.2em;
}

.backgroundImage {
  /* background: url("../pictures/backgroundNew.png"); */
  background-image: linear-gradient(to top, #454250, #10182073),
    url(https://assets.gettyimages.com/bf-boulder-whitelabelbucket-getty-prod/98hjm4j449qv93jpvq7qb9g7/v/53462656/original/1219375103.jpg);
  background-color: "#E5E5E5";
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center !important;
  background-size: cover !important;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  font-family: "Open Sans", sans-serif;
  /* letter-spacing: 0.2em; */
}

.productBackgroundBanner {
  background: url("../pictures/backgroundBanner.png");
  background-color: "#E5E5E5";
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center !important;
  background-size: cover !important;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  /* letter-spacing: 0.1em; */
}
/* 
.ant-table-row-selected td {
  background-color: white !important;
} */

/* .ant-table-cell {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
} */
